/* Not "jiffy-api" because it located in the same directory */
import { makePath, makeFullPath } from "common/utils/request/utils";



const makePathFn = (path) =>
  (options = {}) => makePath(path, options);

export default {
  editDekeoFunnelArtworkPath: makePathFn("/print/project/artwork/edit"),
  deliveryInfoDekeoFunnelInterviewPath: makePathFn(
    "/print/project/project-create/delivery_info"
  ),
  zipcodeDekeoFunnelInterviewPath: makePathFn("/print/project/project-create/zipcode"),
  productFacebookConversionApiPath: makePathFn(
    makeFullPath(
      "/api/facebook_conversion_api/event"
    )
  ),
  similarItemsButtonPath: makePathFn(
    makeFullPath(
      "/api/products/:productId/related_products/similar_items_button"
    )
  ),
  similarProductsPath: makePathFn(
    makeFullPath(
      "/api/products/:productId/related_products/similars"
    )
  ),
  matchingProductsPath: makePathFn(
    makeFullPath(
      "/api/products/:productId/related_products/matching"
    )
  ),
  pdpMobileGridWithColorPath: makePathFn(
    makeFullPath(
      "/api/products/:productId/mobile_grid/mobile_grid_color"
    )
  ),
  deliverToModalPath: makePathFn(makeFullPath("/api/deliver_to_modal")),
  popupAddToCartPath: makePathFn(makeFullPath("/api/products/:productId/popup_add_to_cart?ac=:ac")),
  popupColorPickerPath: makePathFn(makeFullPath("/api/products/:productId/popup_color_picker?ac=:ac")),
  colorPickerCompositionsPath: makePathFn(makeFullPath("/api/products/:productId/color_picker_compositions?ac=:ac")),
  dynamicJavascriptsPath: makePathFn(makeFullPath("/api/dynamic_javascripts")),
  uploadCompleteFormTaxExemptionsPath: makePathFn("/tax_exempt/upload_complete_form"),
  getFavoriteItemsPath: makePathFn(makeFullPath("/favorite_items")),
  addFavoriteItemPath: makePathFn(makeFullPath("/favorite_items")),
  removeFavoriteItemPath: makePathFn(makeFullPath("/favorite_items/:ac")),
  unsubscribeFavoriteItemPath: makePathFn(makeFullPath("/favorite_items/:ac/unsubscribe")),
  updateNoteFavoriteItemPath: makePathFn(makeFullPath("/favorite_items/:ac/update_note")),
  updateCheckoutPath: makePathFn(makeFullPath("/checkout/update/:state")),
  googlePayGuestPath: makePathFn(makeFullPath("/checkout/google_pay/guest")),
  prepareKlarnaOrderPath: makePathFn(makeFullPath("/checkout/klarna/prepare_order")),
  generateBraintreeTokenPath: makePathFn(makeFullPath("/checkout/braintree/generate_token")),
  paypalFailedPath: makePathFn(makeFullPath("/checkout/paypal/failed")),
  thankYouPath: makePathFn(makeFullPath("/checkout/thankyou")),
  compositionsPath: makePathFn(makeFullPath("/api/products/:productId/compositions")),
  currentPricesPath: makePathFn(makeFullPath("/api/products/:productId/cp")), /* TODO: Make a name in routes for this endpoint */
  cartPath: makePathFn(makeFullPath("/cart")),
  updateOrderFeatureUsagePath: makePathFn(
    makeFullPath("/api/v2/order/:order_number/feature_usage.json")
  ),
  pdpDeliveryEstimatesPath: makePathFn(
    makeFullPath(
      "/api/products/:productId/delivery_estimates/dates"
    )
  ),
  pdpDeliveryEstimatesForCurrentCartPath: makePathFn(
    makeFullPath(
      "/api/products/:productId/delivery_estimates/current_pdp_date"
    )
  ),
  compositionEstimationDatePath: makePathFn(makeFullPath("/api/v2/estimates/composition_dates.json")),
  chatGptSessionPath: makePathFn(makeFullPath("/chatgpt/session_id")),
  chatGptChatsPath: makePathFn(makeFullPath("/chatgpt/chats")),
  chatGptDeleteChatPath: makePathFn(makeFullPath("/chatgpt/delete_chat")),
  searchQuotesPath: makePathFn(makeFullPath("/cart_quotes/search")),
  savedCartsAssignmentPath: makePathFn(makeFullPath("/api/v2/saved_carts/assignment_data.json")),
  runQuoteAssignmentPath: makePathFn(makeFullPath("/cart_quotes/run_assignment")),
  rateBotMessagePath: makePathFn(makeFullPath("/cart_quotes/review_message")),
  updateAiMessagePath: makePathFn(makeFullPath("/cart_quotes/update_ai_message")),
  crafterpayCreateQuotePath: makePathFn(makeFullPath("/jiffyhub/crafters/quotes")),
  crafterpayQuotePath: makePathFn(makeFullPath("/jiffyhub/crafters/quotes/:quote_guid.json")),
  crafterpayDeactivateQuotePath: makePathFn(makeFullPath("/jiffyhub/crafters/quotes/:quote_id/deactivate")),
  crafterpayEditQuotePath: makePathFn(makeFullPath("/jiffyhub/crafters/quotes/:quote_id/edit.html")),
  crafterpayEditFormQuotePath: makePathFn(makeFullPath("/jiffyhub/crafters/quotes/:quote_id/edit.json")),
  crafterpayProposeQuotePath: makePathFn(makeFullPath("/jiffyhub/crafters/quotes/:quote_id/propose")),
  crafterpayShareQuoteEmailPath: makePathFn(makeFullPath("/jiffyhub/crafters/quotes/:quote_id/share_email")),
  crafterpayUpdateQuoteShippingCostPath: makePathFn(makeFullPath("/jiffyhub/crafters/quotes/:quote_id/update_shipping_cost")),
  crafterpayUpdateQuoteTotalPath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:id/update_total.json")),
  crafterpayUpdateQuoteCrafterMessagePath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:id/update_crafter_message.json")),
  crafterpayUpdateQuoteCompletionDatePath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:id/update_completion_date.json")),
  crafterpayUpdateQuoteDeliveryDatePath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:id/update_delivery_date.json")),
  crafterpayUpdateQuoteDeliveryMethodPath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:id/update_delivery_method.json")),
  crafterpayUpdateQuoteInvoiceDetailsPath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:id/update_invoice_details.json")),
  crafterpayUpdateQuoteDueDatePath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:id/update_due_date.json")),
  crafterpayUpdateQuoteBalanceDueDatePath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:id/update_balance_due_date.json")),
  crafterpayOnboardingPath: makePathFn(makeFullPath("/jiffyhub/crafters/onboarding")),
  crafterpayLineItemsPath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:quote_id/line_items.json")),
  crafterpayLineItemPath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:quote_id/line_items/:id.json")),
  crafterpayLineItemAddImagePath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:quote_id/line_items/:id/add_image")),
  crafterpayLineItemRemoveImagePath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:quote_id/line_items/:id/remove_image")),
  consumersPath: makePathFn(makeFullPath("/api/consumers")),
  consumerPath: makePathFn(makeFullPath("/api/consumers/:consumerId")),
  attachConsumerToOrderPath: makePathFn(makeFullPath("/api/orders/:orderId/attach_consumer")),
  detachConsumerFromOrderPath: makePathFn(makeFullPath("/api/orders/:orderId/detach_consumer")),
  savedCartsPath: makePathFn(makeFullPath("/api/v2/saved_carts.json")),
  switchSavedCartPath: makePathFn(makeFullPath("/api/v2/saved_carts/:number/switch.json")),
  consumerAddressPath: makePathFn(makeFullPath("/api/consumers/:consumerId/address")),
  savedCartsRecalculatePath: makePathFn(makeFullPath("/api/consolidation/calculate")),
  moreDecoratorReviewsPath: makePathFn(makeFullPath("/api/reviews/load_more")),
  savedCartsListPath: makePathFn(makeFullPath("/jiffyhub/saved_carts")),
  productReviewsPath: makePathFn(makeFullPath("/api/products/:productId/reviews")),
  apiSearchProductsPath: makePathFn(makeFullPath("/api/search_products")),
  etsyConnectedPath: makePathFn(makeFullPath("/jiffyhub/etsy/connected")),
  shopifyConnectedPath: makePathFn(makeFullPath("/jiffyhub/shopify/connected")),
  squareConnectedPath: makePathFn(makeFullPath("/jiffyhub/square/connected")),
  woocommerceConnectedPath: makePathFn(makeFullPath("/jiffyhub/woocommerce/connected")),
  merchantVariantsPath: makePathFn(makeFullPath("/jiffyhub/merchant_listings/:listingId/merchant_variants")),
  unmappedLineItemsPath: makePathFn(makeFullPath("/api/v2/unmapped_line_items.json")),
  productSizesColorsPath: makePathFn(makeFullPath("/jiffyhub/merchant_listings/:listingId/merchant_variants/product_color_sizes")),
  updateMerchantVariantPath: makePathFn(makeFullPath("/jiffyhub/merchant_listings/:listingId/merchant_variants/:id")),
  unassignedMerchantListingsPath: makePathFn(makeFullPath("/jiffyhub/merchant_listings/unassigned")),
  undoAssignMerchantListingsPath: makePathFn(makeFullPath("/jiffyhub/merchant_listings/undo_assign")),
  syncMerchantListingsPath: makePathFn(makeFullPath("/jiffyhub/merchant_listings/sync")),
  merchantListingsPath: makePathFn(makeFullPath("/jiffyhub/merchant_listings/:id")),
  popularProductsPath: makePathFn(makeFullPath("/jiffyhub/products/popular")),
  bulkUpdateMerchantVariantPath: makePathFn(makeFullPath("/jiffyhub/merchant_listings/:listingId/merchant_variants/bulk_update")),
  adminMerchantVariantsPath: makePathFn(makeFullPath("/admin/merchant_variants")),
  adminProductSizesColorsPath: makePathFn(makeFullPath("/admin/merchant_variants/product_color_sizes")),
  adminUpdateMerchantVariantPath: makePathFn(makeFullPath("/admin/merchant_listings/:listingId/merchant_variants/:id")),
  adminBulkUpdateMerchantVariantPath: makePathFn(makeFullPath("/admin/merchant_variants/bulk_update")),
  updateNotificationSettingPath: makePathFn(makeFullPath("/api/account/notification_setting")),
  updateWebsiteNotification: makePathFn(makeFullPath("/api/website_notifications/:id")),
  websiteNotificationsList: makePathFn(makeFullPath("/api/website_notifications")),
  loggerCheckoutPath: makePathFn(makeFullPath("/logger/checkout")),
  setUseStoreCreditsPath: makePathFn(makeFullPath("/api/v2/carts/set_use_store_credits.json")),
  attachConsumerWithQuotePath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:id/attach_consumer.json")),
  crafterpaySaveQuoteDepositPath: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:quote_id/save_deposit")),
  invoicesListPath: makePathFn(makeFullPath("/jiffyhub/crafters/invoices")),
  mobileMenuItemsPath: makePathFn(makeFullPath("/api/menu_items/mobile_menu_items")),
  mobileMenuBannerPath: makePathFn(makeFullPath("/api/menu_items/mobile_menu_banner")),
  crafterpayUpdateCrafterPath: makePathFn(makeFullPath("/jiffyhub/crafters/api/crafters/:id")),
  crafterpayUpdateLogoCrafterPath: makePathFn(makeFullPath("/jiffyhub/crafters/api/crafters/:id/update_logo")),
  crafterpayCreateAddressPath: makePathFn(makeFullPath("/jiffyhub/crafters/blanks_orders/:orderNumber/create_address")),
  crafterpayCalculateBalanceAndDeposit: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:quote_id/calculate_balance_and_deposit")),
  crafterpayPreviewEarnings: makePathFn(makeFullPath("/jiffyhub/crafters/api/quotes/:quote_id/preview_earnings")),
  crafterpayCompleteOrderPath: makePathFn(makeFullPath("/jiffyhub/crafters/orders/:orderNumber/complete")),
  crafterpayCheckInvoiceRefreshRequiredPath: makePathFn(makeFullPath("/quotes/:quote_id/check_refresh_required")),
  stripeCreateIntentPath: makePathFn(makeFullPath("/stripe/create_intent")),
  stripeProviderGuestPath: makeFullPath("/checkout/stripe/:paymentMethodType/guest"),
  startSezzleCheckoutPath: makePathFn(makeFullPath("/sezzle/checkout")),
  sezzleGuestPath: makeFullPath("/checkout/sezzle/guest"),
  borneoRootPath: makePathFn(makeFullPath("/borneo/artboard")),
  borneoAssetUploadPath: makePathFn(makeFullPath("/api/borneo/assets")),
  dtfUploadFromBorneoPath: makePathFn(makeFullPath("/api/users/dtf_artworks")),
  claimsUploadImagesPath: makePathFn(makeFullPath("/orders/claims/upload_images.json")),
  digitalAssetsFacetsPath: makePathFn(makeFullPath("/api/designs/facets")),
  adminReorderAssetsPath: makePathFn(makeFullPath("/admin/digital-assets/:assetId/reorder_previews")),
  claimsCancelCompletePath: makePathFn(makeFullPath("/orders/cancel/complete.js"))
};
